import { gql } from "graphql-tag";
import { PRODUCT_GROUP_FIELDS , GROUP_PRODUCTS_FIELDS } from "./Fragments";

export const CREATE_PRODUCT_GROUP = gql`
	mutation CreateProductGroup($input: ProductGroupInput!) {
		create_product_group(product_group: $input) {
			...ProductGroupFields
		}
	}
	${PRODUCT_GROUP_FIELDS}
`;

export const UPDATE_PRODUCT_GROUP = gql`
	mutation UpdateProductGroup($input: ProductGroupInput!) {
		update_product_group(product_group: $input) {
			...ProductGroupFields
		}
	}
	${PRODUCT_GROUP_FIELDS}
`;

export const UPDATE_GROUP_PRODUCTS = gql`
	mutation UpdateGroupProducts($input: GroupProductsInput!) {
	
		update_group_products(group_products: $input)
	}
`;

export const DELETE_PRODUCT_GROUP = gql`
	mutation DeleteProductGroup($id: Int!) {
		delete_product_group(id: $id) {
			...ProductGroupFields
		}
	}
	${PRODUCT_GROUP_FIELDS}
`;

export default { PRODUCT_GROUP_FIELDS, CREATE_PRODUCT_GROUP, UPDATE_PRODUCT_GROUP, DELETE_PRODUCT_GROUP, UPDATE_GROUP_PRODUCTS };
