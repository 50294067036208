
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { Input } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_PRODUCT_GROUP } from '@/modules/catalog/product-groups/graphql/Queries';
import { CREATE_PRODUCT_GROUP } from '@/modules/catalog/product-groups/graphql/Mutations';
import { AddProductGroupForm } from '@/modules/catalog/product-groups/interfaces/index';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'add new customer group',
    components: {
        Input,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const addProductGroupForm = ref<null | HTMLFormElement>(null);

        // Given Add Customer Group Form Interface
        const productGroupData = ref<AddProductGroupForm>({
            name: '',
            uuid: ''
        });

        // Submit Hanlder Request
        const submitHandlerForm = async () => {
            addProductGroupForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: productGroupData.value.name
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_PRODUCT_GROUP,
                        variables: { input: formData },
                        update: (store, { data: { create_product_group } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_PRODUCT_GROUP,
                                variables: {
                                    page: 1,
                                    limit: 10
                                }
                            }) as Record<any, any>;
                            store.writeQuery({
                                query: GET_PRODUCT_GROUP,
                                variables: {
                                    page: 1,
                                    limit: 10
                                },
                                data: {
                                    product_groups: {
                                        ...pervious_record.product_groups,
                                        data: [create_product_group, ...pervious_record.product_groups.data]
                                    }
                                }
                            });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            addProductGroupForm.value?.resetFields();
        };

        // Emitter To Open Model
        emitter.on('AddProductGroupAction', () => {
            resetForm();
            modal.value = new Modal(document.getElementById('modal_add_product_group'));
            modal.value.show();
        });

        return {
            loading,
            productGroupData,
            addProductGroupForm,
            submitHandlerForm
        };
    }
});
