import { gql } from "graphql-tag";

export const PRODUCT_GROUP_FIELDS = gql`
  fragment ProductGroupFields on ProductGroup {
    id
    name
    uuid
    path
    created_at
  }
`;

export const PRODUCT_GROUP_PRODUCT_FIELDS = gql`
  fragment ProductGroupProductFields on ProductGroupProducts {
    id
    product_id
    products {
      id
      description {
          name
      }
    }
  }
`;

export const GROUP_PRODUCTS_FIELDS = gql`
  fragment GroupProductsFields on ProductGroup {
    id
    products {
      id
      status
      available_stock
      economic_number
      description {
          name
      }
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on ProductGroupPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { PRODUCT_GROUP_FIELDS, PAGINATION, GROUP_PRODUCTS_FIELDS, PRODUCT_GROUP_PRODUCT_FIELDS };