import { gql } from "graphql-tag";
import { PRODUCT_GROUP_FIELDS, PAGINATION, GROUP_PRODUCTS_FIELDS, PRODUCT_GROUP_PRODUCT_FIELDS } from "./Fragments";
import { MANUFACTURER_FIELDS } from "@/modules/catalog/manufacturers/graphql/Fragments";

export const GET_PRODUCT_GROUP = gql`
    query GetProductGroups($page: Int, $limit: Int) {
        product_groups(limit:$limit, page:$page){
            ...Pagination
            data {
                ...ProductGroupFields
            }
        }
    }
    ${PRODUCT_GROUP_FIELDS},
    ${PAGINATION},
`;


export const GET_PRODUCT_GROUP_PRODUCTS = gql`
    query GetProductGroupProducts($page: Int, $limit: Int, $id: Int) {
        product_group_products(limit:$limit, page:$page, id:$id)
    }
`;

export const SEARCH_PRODUCT_GROUPS = gql`
    query SearchProductGroups($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String) {
        search_product_groups(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type){
            ...Pagination
            data {
                ...ProductGroupFields
            }
        }
    }
    ${PRODUCT_GROUP_FIELDS},
    ${PAGINATION},
`;

export const GET_GROUP_PRODUCTS = gql`
    query GetGroupProducts($product_group_id: Int) {
        group_products(product_group_id: $product_group_id) {
            ...GroupProductsFields
        }
    }
    ${GROUP_PRODUCTS_FIELDS}
`;

export default { GET_PRODUCT_GROUP, SEARCH_PRODUCT_GROUPS, GROUP_PRODUCTS_FIELDS };
